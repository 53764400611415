import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'uatdemo',

  ping: {
    clientId: '6b910d38-1eeb-46da-b615-da34bd2def60',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  ecomm: {
    baseUrl: 'https://ecomm.demo.us.regional.uat.cf.10piececombo.com',
    menuUrl: 'https://ecomm.demo.us.regional.uat.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.demo.us.regional.uat.cf.10piececombo.com'
  },
  featureFlags: {
    ...base.featureFlags
  },
};
